
import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable()
export class CustomToast {

  text: string;

  constructor(public toastCtrl: ToastController) {
    console.log('Hello CustomToast');
   // this.text = 'Hello World';
  }

  async showError(msg)
  {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      cssClass: 'toast-err',
    });

    await (await toast).dismiss(() => {
      console.log('Dismissed toast');
    });
      
    await toast.present();
  }

  async showSuccess(msg)
  {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      cssClass: 'toast-success',
    });

    await toast.dismiss(() => {
      console.log('Dismissed toast');
    });
      
    await toast.present();
  }

  async showWarning(msg)
  {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 1500,
      position: 'top',
      cssClass: 'toast-warning',
    });

    await toast.dismiss(() => {
      console.log('Dismissed toast');
    });
      
    await toast.present();
  }

  async showSuccessLong(msg)
  {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 4000,
      position: 'top',
      cssClass: 'toast-success',
    });

    await toast.dismiss(() => {
      console.log('Dismissed toast');
    });
      
    await toast.present();
  }

}
