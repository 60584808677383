import { UserModel } from "../models/UserModel";

export class Global {
    public accessToken : string;
    public tokenType : string;
    public fcmToken;
    public UserLanguageDetails : any;
    public user : UserModel;
    public notificationReceived : boolean = false;
    public isLoggedIn : boolean = false;
    public sessionTimeoutDuration : number = 15; // mins
    public mainMenu : string = "Home";

    // public baseUrl = 'https://framezapimobapprovertest.diipl.in/api/';
    // public tokenURL = 'https://framezapimobapprovertest.diipl.in/token';
    // public imgDomain = 'https://frameztestconsumer.diipl.in/';

    
    //public baseUrl ='http://localhost:63883/api/';
    //public tokenURL = 'http://localhost:63883/token';
    //public imgDomain = 'http://localhost:63883/';

     public baseUrl = 'https://trainingaprvapi.ufoframez.com/api/';
     public tokenURL = 'https://trainingaprvapi.ufoframez.com/token';
     public imgDomain = 'https://trainingconsumer.ufoframez.com/';
  
    mediaList : any;
    approvedMediaList : any;
    public isPopupActive : boolean = false;
    
    
}