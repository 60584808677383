import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { Global } from 'src/global/global';
import { ApiProvider } from 'src/providers/api/api';
import { CustomToast } from 'src/providers/toast/custom-toast';
import { MediaPreviewPage } from '../media-preview/media-preview.page';
import { MediaListService } from '../../models/media-list.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage {
  tooltipVisible = false;
  // mediaList : any;
  mediaList: any[] = [];
  paginatedMediaList: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;

  constructor(
    public navCtrl: NavController, 
    public globalData : Global,
    private loadingCtrl: LoadingController,
    public apiProvider : ApiProvider,
    public toast : CustomToast, 
    public modalCtrl : ModalController, 
    public platform: Platform,
    public router:Router,
    private mediaListService: MediaListService
    ) {
this.getMediaToBeApprovedList();
console.log("home page");
this.globalData.mediaList = '';

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.platform.resume.subscribe(() => {
        this.getMediaToBeApprovedList();
      });
    });
  }
  async ionViewWillEnter() {
    await this.getMediaToBeApprovedList();
    this.paginateMediaList();
  }
  toggleTooltip() {
    this.tooltipVisible = !this.tooltipVisible;
  }

  paginateMediaList() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedMediaList = this.globalData.mediaList.slice(0, endIndex);
  }

  async loadMoreData(event) {
    // Simulating asynchronous data loading with a delay
    setTimeout(() => {
      this.currentPage++;
      this.paginateMediaList();
      event.target.complete();

      // Disable infinite scroll when all data is loaded
      if (this.paginatedMediaList.length >= this.globalData.mediaList.length) {
        event.target.disabled = true;
      }
    }, 1000);
  }

  async doRefresh(event) {
    await this.getMediaToBeApprovedList();
    this.currentPage = 1;
    this.paginateMediaList();
    event.target.complete();
  }

  async getMediaToBeApprovedList() {
    const loader = await this.loadingCtrl.create({
      message: 'Please Wait',
    });
    loader.present();
    try {
      const url = this.globalData.baseUrl + 'Media/GetMediaForApprover';
      const body = JSON.stringify({ ApproverID: this.globalData.user.ApproverId });

      const response = await this.apiProvider.postAPICalls(url, body).toPromise();
      const obj = JSON.parse(response);

      if (obj.Code === 1) {
        this.globalData.mediaList = obj.Data; // Replace the global mediaList with the fetched data
        this.sortMediaList(); // Sort the mediaList
        this.paginateMediaList(); // Call paginateMediaList() after fetching the data
      } else {
        this.toast.showError(obj.Message);
      }
    } catch (error) {
      console.error(error);
      this.toast.showError('Error while fetching media data.');
    } finally {
      loader.dismiss();
    }
  }

  sortMediaList() {
    // Sort the global mediaList based on the MediaApprovalStatus
    this.globalData.mediaList.sort((a, b) => {
      const statusA = a.MediaApprovalStatus;
      const statusB = b.MediaApprovalStatus;
      if (statusA === statusB) {
        return 0;
      }
      if (statusA === 1 || statusA === 2 || statusA === 3) {
        return -1;
      }
      return 1;
    });
  }

  async acceptMediaApproverAPI(MediaData){
    const loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    loader.present();

    var url = this.globalData.baseUrl + "Media/AcceptMediaApprover";

    var body = "{\"ApproverID\" : " + this.globalData.user.ApproverId + ", \"MediaID\" : " + MediaData.MediaId + ", \"ApprovalStatus\" : 1 }";

    this.apiProvider.postAPICalls( url , body )
     .subscribe((response) => {
        loader.dismiss();
        console.log("Response is",response);
        var obj = JSON.parse(response);
        if(obj.Code == 1)
        {
        this.toast.showSuccess("Media Accepted for Approval.");
          let navigationExtras: NavigationExtras = {
            queryParams: {
              data: JSON.stringify(MediaData)
            }
        };
        this.router.navigate(['/media-approval'],navigationExtras)
          //this.navCtrl.push(MediaApprovalPage, {"data": MediaData});
        }
        else
        {
          this.toast.showError(obj.Message);          
        }       
        
    }, (error) => {
      loader.dismiss();
      console.log("Error.error is",error.error);
      this.toast.showError("Error while Login");
        //Error Block
    });
  }

  OnContinueApprovingClick(MediaData){
    this.toast.showSuccess("Media Accepted for Approval.");
      let navigationExtras: NavigationExtras = {
        queryParams: {
          data: JSON.stringify(MediaData)
        }
      };
      this.router.navigate(['/media-approval'],navigationExtras)
    //this.navCtrl.push(MediaApprovalPage, {"data": MediaData});
  }
  async previewMediaClicked(pathR)
  {
    // debugger;
    console.log(pathR)
    var path = pathR.ImagePath
    var previewpath= pathR.PreviewImagePath
    if (path.includes("../")) {
        path = path.replace("../", "");
    }

    console.log("Selected Path is ", path)
    var mediaType;
    if (path.includes(".mov") || path.includes(".mp4")) {
      mediaType = 3;
    } else if (path.includes(".mp3") || path.includes(".wav")) {
      mediaType = 2;
    } else if (path.includes(".jpg") || path.includes(".jpeg") || path.includes(".png") || path.includes(".bmp") || path.includes(".gif")) {
      mediaType = 1;
    }
    const modal =  await this.modalCtrl.create({
      component:MediaPreviewPage,
      backdropDismiss:false,
      componentProps: {
        'path':this.globalData.imgDomain + path,
        'previewpath':this.globalData.imgDomain + previewpath,
        'mediaType':mediaType
      }
    });
    return modal.present();
  }
}
