import { Injectable } from '@angular/core';

@Injectable()
export class Config {
	
}

export const firebaseConfig = {
    fire: 
    {    
        apiKey: "AIzaSyBOXKuJ8fzirLn4YnXq7uGbHI-n18MBRgE",
        authDomain: "cinean-approver.firebaseapp.com",
        databaseURL: "https://cinean-approver.firebaseio.com",
        projectId: "cinean-approver",
        storageBucket: "",
        messagingSenderId: "378078691826",
        appId: "1:378078691826:web:22ead99621e5601d856b6b"
    }
}