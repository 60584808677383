import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Global } from "../../global/global";


@Injectable()
export class ApiProvider {
 
  constructor(public http: HttpClient,public globalData : Global) { }
 
  getAPICalls(url) {

    if(url.includes('latlng') || url.includes('address'))
    {
        var headers = new HttpHeaders();
    }
    else
    {
        var headers = new HttpHeaders();
        headers = headers.append('Authorization', this.globalData.tokenType + " " + this.globalData.accessToken);
        headers = headers.append('Content-Type', 'application/json');
    }
    
    return this.http.get(url,{ 'headers': headers, responseType: 'text' });
  }

  postAPICalls(url,body) {

    var headers = new HttpHeaders();
    if(url.includes('RegisterORUpdateApproverUser'))
    {       
        headers = headers.append('Authorization', this.globalData.tokenType + " " + this.globalData.accessToken);
    }
    else if(url.includes('token')){
        headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    }
    else
    {
        headers = headers.append('Authorization', this.globalData.tokenType + " " + this.globalData.accessToken);
        headers = headers.append('Content-Type', 'application/json');
    }
    
    return this.http.post(url,body,{ 'headers': headers, responseType: 'text' });
  }
}