export class UserModel
{
    LoginUserID : string;
    Name : string;
    ContactNumber : string;
    EmailId : string;
    Password : string;
    socialId : string;
    socialLogin : boolean = false;
    LanguageID : any = [];
    ApproverId: string;
    languageID : string;
    language: string;
    newPassword : string;
    confirmPassword : string;
    file:File;
}