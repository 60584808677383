import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController, NavController } from '@ionic/angular';
import { AngularFireAuth } from 'angularfire2/auth';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Global } from 'src/global/global';
import { UserModel } from 'src/models/UserModel';
import { ApiProvider } from 'src/providers/api/api';
import { CustomToast } from 'src/providers/toast/custom-toast';

@Component({
  selector: 'app-profile-list',
  templateUrl: './profile-list.page.html',
  styleUrls: ['./profile-list.page.scss'],
})
export class ProfileListPage implements OnInit {

  profileImage:any;
  updateModel: UserModel;
  loader: any;
  lang: any;

  constructor(
    public globalData : Global,
    public navCtrl: NavController,
    public alertCtrl: AlertController,
    public loadingCtrl : LoadingController, 
    public toast : CustomToast,
    public apiProvider : ApiProvider, 
    public modalController : ModalController,
    //public googlePlus : GooglePlus, 
    public afAuth: AngularFireAuth, 
    private imageCompress: NgxImageCompressService,
    public router: Router,
    ) { 
      console.log('Hello ProfileListComponent Component');
      this.getuserdetail();
    }

  ngOnInit() {
  }


  async signoutClicked(){
    let alert = await this.alertCtrl.create({
      header: '',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            
          }
        },
        {
          text: 'Yes',
          handler: () => {
            
            // if ((<any>window).cordova) {
            //   this.googlePlus.logout()
            //   .then(res => {
            //     console.log(res);

            //   })
            //   .catch(err => console.error(err));
            //   //this.facebook.logout();

            //   this.removeFCMToken();

            // }else{
            //   this.afAuth.auth.signOut();
            // }
            
            this.globalData.user = new UserModel();
            this.globalData.isLoggedIn = false;
            localStorage.removeItem("authUser");
            localStorage.setItem("loggedIn",this.globalData.isLoggedIn+"");
            
            this.router.navigate(['/login']);
          }
        }
      ]
    });
    alert.present();  
  }

  changePassword()
  {
    this.router.navigate(['/change-password']);
  }

  async removeFCMToken(){
    const loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    loader.present();

    var url = this.globalData.baseUrl + "User/RemoveApproverDeviceID";
       
    var body = "{\"ApproverId\" : "+ this.globalData.user.ApproverId + " , \"DeviceId\" : \""+ this.globalData.fcmToken +"\" }";

    console.log(body);

    this.apiProvider.postAPICalls( url , body )
     .subscribe((response) => {
        loader.dismiss();
        console.log("Response is",response);
        var obj = JSON.parse(response);
        this.toast.showSuccess(obj.Message);

    }, (error) => {
      loader.dismiss();
      console.log("Error.error is",error.error);
      //this.toast.showError("Error while re");
        //Error Block
    });
  }

  updateUser()
  {
    this.router.navigate(['/update-user']);
  }

  async getuserdetail() {
    this.loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    this.loader.present();
    var url = this.globalData.baseUrl + "User/GetApproverUserDetails";
    var body = "{\"ApproverId\" : " + this.globalData.user.ApproverId + " }";
    console.log(body);

    this.apiProvider.postAPICalls(url, body)
      .subscribe((response) => {
        this.loader.dismiss();
        console.log("Response is", response);
        var obj = JSON.parse(response);
        console.log(obj);
        console.log(obj.Data[0]);
        this.updateModel = obj.Data[0];
        this.profileImage = obj.Data[0].Base64Image;
        console.log(this.profileImage)
       
        console.log(this.updateModel);

        console.log(this.updateModel.languageID);
        var lang = this.updateModel.languageID;
        var lan = this.updateModel.languageID.split("");
        console.log();

        // this.userkyc=this.updateModel.file;
        lan.splice(1, 1);
        lan.splice(2, 1);
        console.log(lan);
        this.lang = lan;
        console.log(this.lang);

      }, (error) => {
        this.loader.dismiss();
        console.log("Error.error is", error.error);

      });
  }

  SelectAndcompressFile() {
    this.imageCompress.uploadFile().then(({image, orientation}) => { 
      console.log('Size in bytes was:', this.imageCompress.byteCount(image)); 
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          console.log('Size in bytes is now:', this.imageCompress.byteCount(result));
          this.profileImage = result;
          this.updateProfile();
        }); 
    }); 
  }

  async updateProfile(){
    const loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    loader.present();

    console.log(this.lang);
    console.log(this.updateModel.Name);
    console.log(this.updateModel);
    console.log(this.updateModel.languageID);

    var body = new FormData();
    body.append('ProfileImageBase64', this.profileImage);
    body.append('Name', this.updateModel.Name);
    body.append('ContactNumber', this.updateModel.ContactNumber);
    body.append('EmailId', this.updateModel.EmailId);
    body.append('Password', this.updateModel.Password);
    body.append('socialLogin', JSON.stringify(this.updateModel.socialLogin));
    body.append('LanguageID', this.lang);
    body.append('file', this.updateModel.file);
   
    body.append('ProfileImageName', 'abc.jpg');



    body.append('ApproverId', JSON.stringify(this.updateModel.ApproverId));
    var url = this.globalData.baseUrl + "User/RegisterORUpdateApproverUser";

    console.log(url);
    console.log(body)

    this.apiProvider.postAPICalls(url, body)
      .subscribe((response) => {
        console.log(response);
        console.log(JSON.parse(response));
        this.getuserdetail();
        loader.dismiss();
      }, (error) => {
        console.log("Error.error is", error.error);
        loader.dismiss();
      });
  }


}
