import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { Global } from 'src/global/global';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.page.html',
  styleUrls: ['./media-preview.page.scss'],
})
export class MediaPreviewPage implements OnInit {

  imagePath : string;
  videoPath : string;
  audioPath : string;
  @Input() mediaType : number;
  @Input() path: any;
  @Input() previewpath: any;
  isMobile :boolean = false;

  constructor(
    public navCtrl: NavController, 
    public globalData : Global,
    public modalCtrl: ModalController, 
    public platform : Platform,
    public router:Router,
    public route:ActivatedRoute,
    ) 
  {
    
    if ((<any>window).cordova){
    //if (this.platform.is('ios') || this.platform.is('android')) {
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }
    this.globalData.isPopupActive = true;
  }

  ngOnInit() {
    // debugger
    if(this.mediaType == 1){
      this.imagePath = this.path;
      console.log("Image Path in Media Preview is", this.imagePath)
    }else if(this.mediaType == 3){
      this.videoPath = this.path;
      console.log("Video Path in Media Preview is", this.videoPath)
    }
    else if(this.mediaType == 2){
      this.imagePath=this.previewpath;
      this.audioPath =this.path;
      console.log("Image with audio Path in Media Preview is", this.audioPath)
    }
  }
  ionViewDidLoad() {
    console.log('ionViewDidLoad MediaPreviewPage');
  }

  close()
  {
    this.globalData.isPopupActive = false;
    this.modalCtrl.dismiss();
  }

  preview(path : any){
    // if ((<any>window).cordova){
    //   this.photoViewer.show(path);
    // }
  }

}
