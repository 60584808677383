import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, Platform } from '@ionic/angular';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Global } from 'src/global/global';
import { UserModel } from 'src/models/UserModel';
import { ApiProvider } from 'src/providers/api/api';
import { CustomToast } from 'src/providers/toast/custom-toast';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(platform: Platform, 
   // private fcm: FCM, 
    public globalData : Global, 
    private ref: ChangeDetectorRef,
    public apiProvider : ApiProvider, 
    public toast : CustomToast,
    public idle: Idle, 
    public loadingCtrl : LoadingController,
    public router: Router,
    ) {
      platform.ready().then(() => {  
        this.generateToken();
  
        if ((<any>window).cordova){
          if(localStorage.getItem("loggedIn") !== null && localStorage.getItem("loggedIn") !== ""){
            this.globalData.isLoggedIn = localStorage.getItem("loggedIn").toLowerCase() == 'true' ? true : false;
          }
        } else{        
  
          if(localStorage.getItem("loggedIn") !== null && localStorage.getItem("loggedIn") !== ""){
            this.globalData.isLoggedIn = localStorage.getItem("loggedIn").toLowerCase() == 'true' ? true : false;
          }
          if(this.globalData.isLoggedIn){
            //check dates
            let loginDate = new Date(localStorage.getItem("loggedinDate"));
            let current_date = new Date();
            var oneMin = 1*60*1000;
            let diffInMin = Math.abs(loginDate.getTime() - current_date.getTime())/oneMin; 
            if(this.globalData.isLoggedIn && diffInMin < this.globalData.sessionTimeoutDuration){
              this.trackSession();
            }else{
              this.globalData.isLoggedIn = false;
              this.globalData.user = new UserModel();
              // localStorage.removeItem("authUser");
              localStorage.setItem("loggedIn",this.globalData.isLoggedIn+"");
              this.router.navigate(['/login']);
              //this.rootPage = LoginPage;
            }
          }
  
        }
        if(this.globalData.isLoggedIn){ // user is already Logged In
          if(localStorage.getItem("authUser") !== null && localStorage.getItem("authUser") !== ""){
            this.globalData.user = <UserModel>JSON.parse(localStorage.getItem("authUser"));
          }
        }
  
        platform.resume.subscribe ( (e) => {
          console.trace("resume called"); 
          if(localStorage.getItem("notificationReceived") != null && localStorage.getItem("notificationReceived") !== "")
            this.globalData.notificationReceived = localStorage.getItem("notificationReceived").toLowerCase() == 'true' ? true : false;     
  
          console.log("this.globalData.notificationReceived in resume "+ this.globalData.notificationReceived);
        });
  
      });
    }

    trackSession(){

      this.idle.setIdle(this.globalData.sessionTimeoutDuration * 60);
      // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
      // this.idle.setTimeout(10000);
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
  
      this.idle.onTimeoutWarning.subscribe((countdown: number) => {
        console.log("timeout");
        if(localStorage.getItem("loggedIn") !== null && localStorage.getItem("loggedIn") !== ""){
          this.globalData.isLoggedIn = localStorage.getItem("loggedIn").toLowerCase() == 'true' ? true : false;
        }
        if(this.globalData.isLoggedIn){
          this.globalData.isLoggedIn = false;
          this.globalData.user = new UserModel();
          localStorage.setItem("loggedIn",this.globalData.isLoggedIn+"");
          //this.rootPage = LoginPage;
          this.router.navigate(['/login']);
        }
        this.idle.stop();
        this.idle.ngOnDestroy();
     });
  
      this.idle.watch();
    }
  
  
    generateToken(){
      var url = this.globalData.tokenURL;  
  
      let body =  "UserName=" +'framezapiuser@diipl.com' + "&Password=" + 'framezapipassword' + "&grant_type=" + 'password';
      
      this.apiProvider.postAPICalls(url,body)
       .subscribe((response) => {
        //loader.dismiss();
          console.log("Response is",response);
          var obj = JSON.parse(response);
          if(obj.access_token){
            this.globalData.accessToken = obj.access_token;
            this.globalData.tokenType = obj.token_type;
  
            this.getAllAdvertiserLanguages();
  
            if((<any>window).cordova){
              this.setNotifications();
            }
  
            if(this.globalData.isLoggedIn && this.globalData.user){
              this.globalData.mainMenu = 'Home';
              this.router.navigate(['/dashboard']);
              //this.rootPage = DashboardPage; 
              if(localStorage.getItem("notificationReceived") != null && localStorage.getItem("notificationReceived") !== "")    
                this.globalData.notificationReceived = localStorage.getItem("notificationReceived").toLowerCase() == 'true' ? true : false;     
  
              console.log("this.globalData.notificationReceived "+ this.globalData.notificationReceived);
            }
            else
              //this.rootPage = LoginPage;
              this.router.navigate(['/login']);
          }        
      }, (error) => {
        
        console.log("Error.error is",error.error);
        this.toast.showError("Authentication Failed");
          //Error Block
      });
       
    }

    getAllAdvertiserLanguages()
    {
      var url = this.globalData.baseUrl + "User/GetApproverLanguageMaster";
      
      this.apiProvider.postAPICalls(url,"") 
      .subscribe((response) => {
        //loader.dismiss();
          var obj = JSON.parse(response);
          if(obj.Code == 1 && obj.Message == "Success"){     
// debugger
            this.globalData.UserLanguageDetails = obj.Data
            console.log("Response is",this.globalData.UserLanguageDetails);
          }
          
      }, (error) => {
        //loader.dismiss();
        console.log("Error.error is",error.error);
      });
    }

    setNotifications()
  {
      // this.fcm.subscribeToTopic('all');
      // this.fcm.getToken().then(token=>{
      //   console.log(token);
      //   this.globalData.fcmToken = token;

      //   if (this.globalData.user != null && this.globalData.user.ApproverId != "0") {
      //     this.sendRegistrationToServer();
      //   }
      // });

      // this.fcm.onNotification().subscribe(data=>{
      //   console.log("IN subscribe");

      //     let self = this;
      //     if(data.wasTapped){
      //       console.log("Received in background");
      //       console.log("Notification received "+JSON.stringify(data))
      //       //this.toast.showSuccess(JSON.stringify(data));
            
      //     } else {
      //       console.log("Received in foreground");
      //       console.log("Notification received "+JSON.stringify(data))
      //       //this.toast.showSuccess(JSON.stringify(data));
      //       var body = data.body;
      //       var mediaID = body.substring(body.indexOf("(")+1, body.indexOf(")"));
      //       //this.toast.showError(mediaID);
      //       var present = this.globalData.mediaList.filter((media)=> {
      //         return media.MediaId == mediaID;          
      //       });
           
      //       if (Array.isArray(present) && present.length == 0) 
      //          this.getMediaToBeApprovedList();

            
      //       // var present = false;

      //       // this.globalData.mediaList.forEach(element => {
      //       //   if(element.MediaId == mediaID){
      //       //     present = true;
      //       //     return;
      //       //   }
      //       // });
     
      //       // if(!present)
      //       //   this.getMediaToBeApprovedList();
      //     }
          
      //     this.globalData.notificationReceived = true;
      //     localStorage.setItem("notificationReceived",this.globalData.notificationReceived+"");
      //     console.log("this.globalData.notificationReceived " + this.globalData.notificationReceived);

      //     this.ref.detectChanges();
      // });
      
      // this.fcm.onTokenRefresh().subscribe(token=>{
      //   console.log(token);
      //   this.globalData.fcmToken = token;

      //   if (this.globalData.user != null && this.globalData.user.ApproverId != "0") {
      //     this.sendRegistrationToServer();
      //   }
      //   //end notifications.
      // });
    
  }

  sendRegistrationToServer(){
    var url = this.globalData.baseUrl + "User/InsertApproverDeviceID";  

    var body = "{\"ApproverId\" : \""+ this.globalData.user.ApproverId +"\",\"DeviceId\" : \""+ this.globalData.fcmToken + "\" }";
    console.log("SEND TOKEN URL " + url);
    console.log("SEND TOKEN BODY " + body);

    this.apiProvider.postAPICalls(url,body)
     .subscribe((response) => {
      //loader.dismiss();
        console.log("Response is FCM",response);

    }, (error) => {
      //loader.dismiss();
      console.log("Error.error is",error.error);
      this.toast.showError("Authentication Failed");
        //Error Block
    });
  }

  async getMediaToBeApprovedList(){
    const loader = await this.loadingCtrl.create({
      message: "Please Wait",
    });
    await loader.present();

    var url = this.globalData.baseUrl + "Media/GetMediaForApprover";

    var body = "{\"ApproverID\" : " + this.globalData.user.ApproverId + "}";

    this.apiProvider.postAPICalls( url , body )
     .subscribe((response) => {
        loader.dismiss();
        console.log("Response is",response);
        var obj = JSON.parse(response);
        if(obj.Code == 1)
        {
          this.globalData.mediaList = null;
          this.globalData.mediaList = obj.Data;          
        }
        else
        {
          this.toast.showError(obj.Message);          
        }       
        
    }, (error) => {
      loader.dismiss();
      console.log("Error.error is",error.error);
      this.toast.showError("Error while Login");
        //Error Block
    });
  }
}
