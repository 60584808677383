import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Keepalive, NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AngularFireModule } from 'angularfire2';
import { firebaseConfig } from 'src/global/config';
import { ApiProvider } from 'src/providers/api/api';
import { Global } from 'src/global/global';
import { CustomToast } from 'src/providers/toast/custom-toast';
import { AngularFireAuth } from 'angularfire2/auth';
//import { GooglePlus } from '@ionic-native/google-plus/ngx';
//import { FCM } from '@ionic-native/fcm/ngx';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SharedModule } from './shared.module';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    HttpClientModule,
    NgIdleKeepaliveModule,
    SharedModule,
    AngularFireModule.initializeApp(firebaseConfig.fire)
  ],
  providers: [
    ApiProvider,
    Global,
    CustomToast,
    //GooglePlus,
    AngularFireAuth,
    //FCM,
    Keepalive,
    NgxImageCompressService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
//