import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ApprovedMediaPage } from './approved-media/approved-media.page';
import { HomePage } from './home/home.page';
import { ProfileListPage } from './profile-list/profile-list.page';

@NgModule({
    imports: [
      CommonModule,
      IonicModule
    ],
    declarations: [
        HomePage,
        ApprovedMediaPage,
        ProfileListPage
    ],
    exports: [
        HomePage,
        ApprovedMediaPage,
        ProfileListPage
    ]
  })
  
  export class SharedModule {}